import FitFinderSdk from "@fit-finder-sdk/fit-finder/dist/fit-finder-sdk";
import thumbnailUrl from "../assets/images/product-bra-demo.jpg";
import { useEffect, useState } from "preact/hooks";
import { IntegrationConfiguration } from "@fit-finder-sdk/shared/src/models/integration-data.models";
import {
  defaultCurrentItemSubgroupId,
  fullBraSizeRange,
  validatedQueryParams,
} from "./query-params";
import { isLeft } from "fp-ts/lib/Either";
import {
  FitFinderSdkHost,
  LegacyFitFinderSdkHost,
} from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import { FC } from "preact/compat";

if (isLeft(validatedQueryParams)) {
  throw new Error(validatedQueryParams.left);
}

const queryParams = validatedQueryParams.right;
console.info("Query Params", queryParams);

type IntegrationOptions = {
  fitFinderSdkHost: string;
  options: IntegrationConfiguration;
};

type LegacyIntegrationOptions = {
  fitFinderSdkHost: string;
  legacyFitFinderSdkHost: string;
};

export type DemoCartItem = {
  itemSubgroupId: string;
  size: string;
};

const mockedIntegrationInterfaceData: IntegrationConfiguration = {
  shop: {
    ...queryParams.shop,
    rootDomain: "localhost",
    consent: {
      hasShopAnalyticsConsent: () =>
        Promise.resolve({
          hasAnalyticsConsent: hasAnalyticsConsent() || false,
        }),
      hasFitFinderAnalyticsConsent: () => hasAnalyticsConsent() || false, //FIXME temporary solution to mock the data
      getFitFinderPrivacyPolicyVersion: () => "v1",
    },
    brandLogo:
      "https://customizations.fitanalytics.com/images/widget_v2/overrides/logos/calvinklein.png",
    experiments: { test: "test" },
    platform:
      window.screen.height < 768 || window.screen.width < 768
        ? "mobile"
        : "desktop",
    iid: Math.random().toString(36).substring(7),
    privateSession: "0",
  },
  product: {
    ...queryParams.product,
    thumbnailUrl,
    addToCart: (itemSubgroupId: string, size: string) => {
      console.log("Integration function - Add to cart:", itemSubgroupId, size);
      const cartItem: DemoCartItem = { itemSubgroupId, size };
      // @ts-ignore
      window.demo.cartItems.push(cartItem);
    },
    buttons: [
      {
        key: "primary",
        anchor: "#fit-finder",
        anchorPlacement: "append",
        shouldShowOutOfStockText: false, //TODO make these values configurable on the demo
        shouldShowOutOfScaleText: true, //TODO make these values configurable on the demo
        text: {},
      },
    ],
  },
  theme: {
    colors: {
      brand: {
        primary: "#24272a",
        hover: "rgba(36, 39, 42, 0.7)",
        active: "rgba(36, 39, 42, 0.4)",
      },
    },
  },
};

window.demo = {
  mockedIntegrationInterfaceData,
  cartItems: [],
};

export const fetchWidgetPDP = function (base: string) {
  return new Promise((resolve) => {
    const scriptElem = document.createElement("script");
    scriptElem.setAttribute("src", `${base}/widget_pdp.js`);
    scriptElem.setAttribute("id", `widget_pdp`);
    scriptElem.setAttribute("type", "text/javascript");
    scriptElem.onload = resolve;
    document.body.appendChild(scriptElem);
  });
};
export const hasAnalyticsConsent = () => {
  return document.querySelector<HTMLInputElement>(
    "#analytics-consent:not(:disabled)",
  )?.checked;
};
const fitAnalyticsData = {
  shopLanguage: "en",
  shopCountry: "US",
  shopSessionId: "fit-finder-demo-ssid-1",
  user: {
    id: "fit-finder-demo-1",
    hasLoggedIn: true,
  },
  mainImageLink:
    "https://images.fitanalytics.com/garments/defshop/300/366182.jpg",
  operations: {
    addToCart: (itemSubgroupId: string, size: string) => {
      console.log("Add to cart", itemSubgroupId, size);
    },
    getSizeChartLink: () => {
      console.log("Get size chart link");
      return document.getElementById("size-chart-link");
    },
  },
  sizes: fullBraSizeRange,
  currentItemSubgroupId: defaultCurrentItemSubgroupId,
  allItemSubgroupIds: [defaultCurrentItemSubgroupId],
  consent: {
    hasAnalyticsConsent,
  },
};

export const startIntegration = async ({
  fitFinderSdkHost,
  options,
}: IntegrationOptions) => {
  const { bootstrapFitAnalyticsSdk } = (await import(
    // We are aware that vite can not resolve dynamic imports with dynamic strings
    // however, this is not a blocker for us as we don't need to resolve the dynamic import at build time
    /* @vite-ignore */
    `${fitFinderSdkHost}/fit-finder-sdk.js?v=${Date.now()}`
  )) as typeof FitFinderSdk;
  return bootstrapFitAnalyticsSdk().then(async (fitAnalyticsSdk) => {
    const fitFinder = await fitAnalyticsSdk.experiences.fitFinder(options);
    if (fitFinder instanceof Error) {
      console.error(fitFinder);
      return;
    } else {
      fitFinder.start();
    }
  });
};

export const startLegacyIntegration = async ({
  fitFinderSdkHost,
  legacyFitFinderSdkHost,
}: LegacyIntegrationOptions) => {
  //@ts-ignore
  window.fitAnalyticsData = { ...fitAnalyticsData };
  //@ts-ignore
  window._fitAnalytics = ({ Integration, AddOn }) => {
    class FitFinderAddOn extends AddOn {
      hasBeenInitialized = false;
      constructor() {
        super();
        this.api = null;
        this.widget = null;
      }
      async onProductLoaded(_: unknown, details: unknown) {
        if (this.widget) {
          return;
        }
        if (
          details instanceof Object &&
          "garment" in details &&
          details.garment instanceof Object &&
          "category" in details.garment &&
          details.garment.category &&
          //@ts-ignore
          _fitAnalytics.integration.isNewFitFinderTakingOver(
            details.garment.category,
          )
        ) {
          console.log("product load", details.garment.category);

          const options =
            //@ts-ignore
            await _fitAnalytics.integration.getStateForNewFitFinder();
          if (!this.hasBeenInitialized) {
            startIntegration({
              fitFinderSdkHost,
              options,
            }).then(() => {
              this.hasBeenInitialized = true;
            });
          }
        }
      }
    }

    const integration = new Integration({
      debug: true,
      addOns: [new FitFinderAddOn()],
      shop: {
        node: window,
        prefix: "americaneagle",
        shopLanguage: {
          path: "fitAnalyticsData.shopLanguage",
        },
        country: {
          path: "fitAnalyticsData.shopCountry",
        },
        shopSessionId: {
          path: "fitAnalyticsData.shopSessionId",
        },
      },

      scope: "body",
      product: {
        container: "#product-container",
        currentSerial: {
          node: window,
          path: "fitAnalyticsData.currentItemSubgroupId",
        },
        allSerials: {
          node: window,
          path: "fitAnalyticsData.allItemSubgroupIds",
        },
        thumbnail: {
          node: window,
          path: "fitAnalyticsData.mainImageLink",
        },
        addToCart: (serial: string, size: string) =>
          //@ts-ignore
          window.fitAnalyticsData.operations.addToCart(serial, size),
        sizes: {
          node: window,
          items: {
            path: "fitAnalyticsData.sizes",
          },
          code: {
            path: "value",
          },
          isAvailable: {
            path: "isAvailable",
          },
          shouldSelectRecommended: false,
        },
        hasManufacturedSizes: true,
      },

      button: {
        anchor: "#fit-finder-cta-container",
        anchorPlacement: "append",
        style: {
          button: {
            "background-color": "black",
          },
          image: {
            background: "red",
          },
          text: {
            background: "blue",
          },
          ariaCSS: {
            "background-color": "green",
          },
        },
      },
      extraButtons: [
        {
          key: "secondary",
          anchor: "#fit-finder-secondary-cta-container",
          anchorPlacement: "prepend",
          buttonAttrs: {
            tabindex: "1",
          },
          style: {
            button: {
              background: "yellow",
            },
          },
        },
      ],

      sizeChartButton: {
        elem: "button.size-chart-button",
        shouldHide: false,
      },
    });
    integration.start();
    //@ts-ignore
    _fitAnalytics.integration = integration;
  };
  await fetchWidgetPDP(legacyFitFinderSdkHost);
};

const buildInitialFitFinderSdkState = () => {
  const hosts: { name: string; value: FitFinderSdkHost }[] = [
    {
      name: "Staging",
      value: "https://fit-finder-sdk-staging.fitanalytics.com/",
    },
    {
      name: "Production",
      value: "https://fit-finder-sdk.fitanalytics.com/",
    },
  ];

  return hosts
    .map((host) => ({
      ...host,
      value: `${host.value}${queryParams.sdk.fitFinderSDKVersion || "current"}`,
    }))
    .concat({ name: "Localhost", value: "http://localhost:3004/" })
    .map((host) => ({
      ...host,
      selected:
        queryParams.sdk.fitFinderSDKHost &&
        host.value.includes(queryParams.sdk.fitFinderSDKHost),
    }));
};

const buildLegacyFitFinderSdkState = () => {
  const hosts: { name: string; value: LegacyFitFinderSdkHost }[] = [
    {
      name: "Legacy: Localhost",
      value: "http://localhost:3000/",
    },
    {
      name: "Legacy: Staging 1",
      value: "https://front-staging1.fitanalytics.com/",
    },
    {
      name: "Legacy: Staging 2",
      value: "https://front-staging2.fitanalytics.com/",
    },
    {
      name: "Legacy: Production",
      value: "https://widget.fitanalytics.com/",
    },
  ];
  return hosts.map((host) => ({
    ...host,
    selected:
      queryParams.sdk.legacyFitFinderSDKHost &&
      host.value.includes(queryParams.sdk.legacyFitFinderSDKHost),
  }));
};

export const CollapsibleContent: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div>
      <button onClick={() => setIsCollapsed(!isCollapsed)}>
        {`${isCollapsed ? "Expand" : "Collapse"} Environment Variables`}
      </button>
      <div style={{ display: isCollapsed ? "block" : "none" }}>{children}</div>
    </div>
  );
};

export function App() {
  // TODO: This should be able to set via query params
  const [fitFinderSDKHosts, setFitFinderSDKHosts] = useState(
    buildInitialFitFinderSdkState(),
  );

  // TODO: This should be able to be set via query params with custom values
  const [legacyFitFinderSDKHosts, setLegacyFitFinderSDKHosts] = useState(
    buildLegacyFitFinderSdkState(),
  );

  useEffect(() => {
    if (queryParams.flags.shouldInitializeFitFinder) {
      onFitFinderInit();
    }
  }, []);

  const selectedFitFinderSDKHost =
    fitFinderSDKHosts.find((host) => host.selected)?.value || "";

  const selectedLegacyFitFinderSDKHost =
    legacyFitFinderSDKHosts.find((host) => host.selected)?.value || "";

  const [useLegacyIntegration, setUseLegacyIntegration] = useState(
    queryParams.flags.useLegacyInteraction,
  );
  const [isConsentReportingEnabled, setIsConsentReportingEnabled] =
    useState(false);
  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useState(false);
  const onFitFinderInit = () => {
    if (useLegacyIntegration) {
      startLegacyIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        legacyFitFinderSdkHost: selectedLegacyFitFinderSDKHost,
      });
    } else {
      startIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        options: mockedIntegrationInterfaceData,
      });
    }
  };
  const [randomProductId, setRandomProductId] = useState("");
  useEffect(() => {
    if (randomProductId) {
      //@ts-ignore
      if (window.fitAnalyticsData?.currentItemSubgroupId) {
        //@ts-ignore
        window.fitAnalyticsData.currentItemSubgroupId = randomProductId;
      }
      mockedIntegrationInterfaceData.product.currentItemSubgroupId.next(
        randomProductId,
      );
    }
  }, [randomProductId]);
  return (
    <>
      <h1>Demo App</h1>
      <br />
      <label>
        <input
          type="checkbox"
          checked={useLegacyIntegration}
          onChange={() => setUseLegacyIntegration(!useLegacyIntegration)}
        />
        Use Legacy Integration Interface
      </label>
      <br />

      <label style={{ marginRight: "20px" }}>
        <input
          id="enable-analytics-consent"
          type="checkbox"
          checked={isConsentReportingEnabled}
          onChange={() =>
            setIsConsentReportingEnabled(!isConsentReportingEnabled)
          }
        />
        Enable Consent Rerporting
      </label>
      <label>
        <input
          id="analytics-consent"
          type="checkbox"
          disabled={!isConsentReportingEnabled}
          checked={hasAnalyticsConsent}
          onChange={() => setHasAnalyticsConsent(!hasAnalyticsConsent)}
        />
        Analytics Consent
      </label>
      <br />
      <br />
      <CollapsibleContent>
        <select
          value={selectedFitFinderSDKHost}
          onChange={(event) => {
            setFitFinderSDKHosts((x) => {
              return x.map((h) => {
                h.selected = h.value === event.currentTarget?.value;
                return h;
              });
            });
          }}
        >
          {fitFinderSDKHosts.map((host) => (
            <option key={host.name} value={host.value}>
              {host.name}
            </option>
          ))}
        </select>
        <br />
        <br />
        <select
          value={selectedLegacyFitFinderSDKHost}
          onChange={(event) => {
            setLegacyFitFinderSDKHosts((x) => {
              return x.map((h) => {
                h.selected = h.value === event.currentTarget.value;
                return h;
              });
            });
          }}
        >
          {legacyFitFinderSDKHosts.map((host) => (
            <option key={host.name} value={host.value}>
              {host.name}
            </option>
          ))}
        </select>
      </CollapsibleContent>

      <br />
      <br />
      <button data-test-id="fit-finder-init-button" onClick={onFitFinderInit}>
        Initialize Fit Finder
      </button>
      <br />
      <br />
      <button
        onClick={() => {
          setRandomProductId(Math.random().toString(36).substring(7));
        }}
      >
        Random Product ID: {randomProductId}
      </button>
      <button
        onClick={() => {
          setRandomProductId(fitAnalyticsData.currentItemSubgroupId);
        }}
      >
        Reset Product ID
      </button>
      <div id="product-container">
        <div id="fit-finder-cta-container"></div>
        <div id="fit-finder-secondary-cta-container"></div>
      </div>
      <div id="fit-finder"></div>
    </>
  );
}
