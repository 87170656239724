import { getApplicativeValidation } from "fp-ts/lib/Either";
import { map } from "fp-ts/Record";
import * as t from "io-ts";

/**
 *
 * @param decoder The decoder to name
 * @param name The name of the decoder
 * @returns A decoder with the name set to the provided name
 *
 * This is useful for debugging and error messages only when your types
 * are simple and not part of a `t.type` or `t.interface` that already has a name.
 * @example
 * ```ts
 * const decoder = t.string;
 * const name = "NamedString";
 * const named = namedDecoder(decoder, name);
 *
 * if(isLeft(named.decode(123))) {
 *  // The error will have the name "NamedString"
 *  console.log(PathReporter.report(named.decode(123)));
 *  // Output: Invalid value 123 supplied to : NamedString
 * }
 * ```
 */
export const namedDecoder = <T>(decoder: t.Type<T>, name: string) =>
  decoder.pipe(decoder, name);

/**
 *
 * @returns a decoder for a function
 * @example
 * ```ts
 * type Func = (a: string) => string;
 * const func: Func = (a: string) => {
 *  return 'test';
 * };
 * const funcDecoder = functionType<Func>();
 *
 * // The decoder will only test that is type function
 * const result = funcDecoder.decode(func);
 *
 * if (isRight(result)) {
 *    // Typescript will infer that result.right is a function that takes a string and returns a string
 *    // However input and output types are not checked at runtime.
 *    result.right('test');
 * }
 *
 * ```
 */
export const functionType = <A>() => {
  return new t.Type<A>(
    "FunctionSignature",
    (u): u is A => typeof u === "function",
    (u, c) => {
      if (typeof u !== "function") {
        return t.failure(u, c);
      }
      return t.success(u as A);
    },
    t.identity,
  );
};

/**
 *
 * @param regex A regular expression to match the string or a function that returns a boolean
 * @param name The name of the type
 * @returns A decoder that will only accept strings that match the regex
 * @example
 * ```ts
 * type MyCustomStringPattern = `custom-${string}`;
 *
 * const regex = /^custom-[a-z]+$/;
 *
 * const name = "MyCustomStringPattern";
 *
 * const stringDecoder = stringMatchType<MyCustomStringPattern>(regex, name);
 *
 * const result = stringDecoder.decode("custom-test");
 * if (isRight(result)) {
 *   // Typescript will infer that result.right is a string that matches the regex
 *   // And io-ts will check that the string matches the regex at runtime
 *   result.right;
 * }
 * ```
 */
export const stringMatchType = <A extends string>(
  test: RegExp | ((s: string) => boolean),
  name: string,
) => {
  return new t.Type<A>(
    name,
    (u): u is A =>
      typeof u === "string" &&
      (typeof test === "function" ? test(u) : test.test(u)),
    (u, c) => {
      if (
        typeof u !== "string" ||
        (typeof test === "function" ? !test(u) : !test.test(u))
      ) {
        return t.failure(u, c);
      }
      return t.success(u as A);
    },
    t.identity,
  );
};

/**
 * Applicative for iotsErrors
 * An util applicative for grouping validations when using sequenceS or sequenceT
 */
export const iotsErrorsApplicativeValidation = getApplicativeValidation({
  concat: (x: t.Errors, y: t.Errors) => x.concat(y),
});

/**
 * It's a helper function to create a partial record
 * @param k A key of the record
 * @param type The type of the record
 * @returns A partial record
 */
export const partialRecord = <
  K extends Record<string, unknown>,
  T extends t.Any,
>(
  k: t.KeyofType<K>,
  type: T,
) => t.partial(map(() => type)(k.keys));
