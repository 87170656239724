import { parseQueryParams } from "@fit-finder-sdk/shared/src/query-parameters/query-parser.ts";
import {
  decodeDemoConfigQueryParameters,
  DemoConfig,
} from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import { isRight, right } from "fp-ts/lib/Either";
import { BehaviorSubject } from "rxjs";
import defaultThumbnailUrl from "../assets/images/product-bra-demo.jpg";

export const fullBraSizeRange = [
  "30A",
  "30B",
  "30C",
  "30D",
  "32AA",
  "32A",
  "32B",
  "32C",
  "32D",
  "32DD",
  "32DDD",
  "34AA",
  "34A",
  "34B",
  "34C",
  "34D",
  "34DD",
  "34DDD",
  "36A",
  "36B",
  "36C",
  "36D",
  "36DD",
  "38B",
  "38C",
  "38D",
  "38DD",
  "40C",
  "40D",
  "40DD",
].map((size) => ({ isAvailable: true, value: size }));

export const defaultCurrentItemSubgroupId = "0738_5046_331";
const defaultQueryParams: DemoConfig = {
  sdk: {
    fitFinderSDKHost: "http://localhost:3004/",
    legacyFitFinderSDKHost: "https://widget.fitanalytics.com/",
  },
  shop: {
    shopPrefix: "americaneagle",
    shopCountry: "US" as const,
    shopLanguage: "en" as const,
    shopSessionId: "ssidCookie",
    sessionId: "sessionId",
    shortSid: "shortSid",
    userId: "",
  },
  product: {
    thumbnailUrl: defaultThumbnailUrl,
    currentItemSubgroupId: new BehaviorSubject(defaultCurrentItemSubgroupId),
    allItemSubgroupIds: [defaultCurrentItemSubgroupId],
    sizes: fullBraSizeRange,
  },
  flags: {
    useLegacyInteraction: false,
    shouldInitializeFitFinder: false,
  },
};

const validateQueryParams = () => {
  const queryParams = parseQueryParams(location.search, ["config"] as const);

  if (!queryParams.config) {
    return right(defaultQueryParams);
  }
  const decodedDemoConfig = decodeDemoConfigQueryParameters(queryParams.config);
  if (isRight(decodedDemoConfig)) {
    return right({
      sdk: {
        fitFinderSDKHost: decodedDemoConfig.right.fitFinderSDKHost,
        fitFinderSDKVersion: decodedDemoConfig.right.fitFinderSDKVersion,
        legacyFitFinderSDKHost: decodedDemoConfig.right.legacyFitFinderSDKHost,
      },
      shop: {
        shopPrefix: decodedDemoConfig.right.shopPrefix,
        shopCountry: decodedDemoConfig.right.shopCountry,
        shopLanguage: decodedDemoConfig.right.shopLanguage,
        userLanguage: decodedDemoConfig.right.userLanguage,
        shopSessionId: decodedDemoConfig.right.shopSessionId,
        sessionId: decodedDemoConfig.right.sid,
        shortSid: decodedDemoConfig.right.shortSid,
        userId: decodedDemoConfig.right.userId,
      },
      product: {
        thumbnailUrl: decodedDemoConfig.right.thumbnailUrl,
        currentItemSubgroupId: new BehaviorSubject(
          decodedDemoConfig.right.currentItemSubgroupId,
        ),
        allItemSubgroupIds: [decodedDemoConfig.right.currentItemSubgroupId],
        sizes: decodedDemoConfig.right.sizes,
      },
      flags: {
        useLegacyInteraction: decodedDemoConfig.right.useLegacyInteraction,
        shouldInitializeFitFinder:
          decodedDemoConfig.right.shouldInitializeFitFinder,
      },
    } satisfies DemoConfig);
  } else {
    return decodedDemoConfig;
  }
};

export const validatedQueryParams = validateQueryParams();
