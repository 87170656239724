import * as t from "io-ts";

export const StyleRules = t.record(
  t.string,
  t.union([t.string, t.number, t.undefined, t.null]),
);
export type StyleRules = t.TypeOf<typeof StyleRules>;

export const compileStyleRulesToString = (
  rules: StyleRules | undefined,
): string => {
  const style: string[] = [];
  if (!rules) return "";

  for (const [key, value] of Object.entries(rules)) {
    if (value !== null && value !== undefined) {
      style.push(`${key}:${value}`);
    }
  }

  return style.length ? style.join(";") + ";" : "";
};

export const compileStyleRulesToJsObject = (
  rules: StyleRules | undefined,
): StyleRules => {
  const style: StyleRules = {};
  if (!rules) return style;

  for (const [key, value] of Object.entries(rules)) {
    if (value !== null && value !== undefined) {
      const camelCaseKey = kebabCaseToCamelCase(key);
      style[camelCaseKey] = value;
    }
  }

  return style;
};

export const kebabCaseToCamelCase = (kebabCase: string): string =>
  kebabCase.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
