import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import {
  CountryCode,
  LanguageCode,
} from "@fit-finder-sdk/client-api/dist/io-ts-gen-types";
import { Sizes } from "../models/integration-data.models";
import { getKeys } from "../utils/utils";
import { chain, isRight, left, map, right, tryCatch } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import type { BehaviorSubject } from "rxjs";

export const RequiredDemoConfigQueryParam = t.type({
  currentItemSubgroupId: t.string,
  shopPrefix: t.string,
  shopLanguage: LanguageCode,
  shopCountry: CountryCode,
  sizes: Sizes,
  sid: t.string,
  shortSid: t.string,
  shopSessionId: t.string,
});

export type RequiredDemoConfigQueryParam = t.TypeOf<
  typeof RequiredDemoConfigQueryParam
>;

export const FitFinderSdkVersion = t.brand(
  t.string,
  (
    s,
  ): s is t.Branded<
    `versions/${string}` | "current/" | "/",
    { readonly FitFinderSdkVersion: unique symbol }
  > => s.startsWith("versions/") || s === "current/" || s === "/",
  "FitFinderSdkVersion",
);

export type FitFinderSdkVersion = t.TypeOf<typeof FitFinderSdkVersion>;

export const FitFinderSdkHost = t.union([
  t.literal("http://localhost:3004/"),
  t.literal("https://fit-finder-sdk.fitanalytics.com/"),
  t.literal("https://fit-finder-sdk-staging.fitanalytics.com/"),
]);

export type FitFinderSdkHost = t.TypeOf<typeof FitFinderSdkHost>;

export const LegacyFitFinderSdkHost = t.union([
  t.literal("http://localhost:3000/"),
  t.literal("https://widget.fitanalytics.com/"),
  t.literal("https://front-staging1.fitanalytics.com/"),
  t.literal("https://front-staging2.fitanalytics.com/"),
]);

export type LegacyFitFinderSdkHost = t.TypeOf<typeof LegacyFitFinderSdkHost>;

export const FitFinderDemoHost = t.union([
  t.literal("http://localhost:5173/"),
  t.literal("http://host.docker.internal:5173/"),
  t.literal("https://fit-finder-demo.fitanalytics.com/"),
  t.literal("https://fit-finder-demo-staging.fitanalytics.com/"),
]);

export type FitFinderDemoHost = t.TypeOf<typeof FitFinderDemoHost>;

export const OptionalDemoConfigQueryParam = t.partial({
  userLanguage: LanguageCode,
  useLegacyInteraction: t.boolean,
  userId: t.string,
  shouldInitializeFitFinder: t.boolean,
  thumbnailUrl: t.string,
  fitFinderSDKHost: FitFinderSdkHost,
  fitFinderSDKVersion: FitFinderSdkVersion,
  legacyFitFinderSDKHost: LegacyFitFinderSdkHost,
});

export type OptionalDemoConfigQueryParam = t.TypeOf<
  typeof OptionalDemoConfigQueryParam
>;

export const DemoConfigQueryParam = t.intersection([
  RequiredDemoConfigQueryParam,
  OptionalDemoConfigQueryParam,
]);

export const demoQueryParamKeys = getKeys({
  ...RequiredDemoConfigQueryParam.props,
  ...OptionalDemoConfigQueryParam.props,
});

export type DemoConfigQueryParam = t.TypeOf<typeof DemoConfigQueryParam>;

export type DemoQueryParameter = (typeof demoQueryParamKeys)[number];

export const DemoQueryParams = t.partial({
  config: DemoConfigQueryParam,
});

export const decodeDemoConfigQueryParameters = (config: string) => {
  return pipe(
    tryCatch(
      () => {
        const demoConfigString = atob(config);
        const demoConfig = JSON.parse(demoConfigString);
        return demoConfig;
      },
      () => "Error parsing query params",
    ),
    chain((demoConfig) => {
      const decodedDemoConfig = DemoConfigQueryParam.decode(demoConfig);
      return isRight(decodedDemoConfig)
        ? right(decodedDemoConfig.right)
        : left(
            `Error decoding query params ${PathReporter.report(decodedDemoConfig)}`,
          );
    }),
  );
};

export const encodeDemoConfigQueryParameters = (
  config: DemoConfigQueryParam,
) => {
  return pipe(
    tryCatch(
      () => JSON.stringify(config),
      () => "Error encoding query params",
    ),
    map((configString) => btoa(configString)),
  );
};

export type DemoConfig = {
  sdk: {
    fitFinderSDKHost?: OptionalDemoConfigQueryParam["fitFinderSDKHost"];
    fitFinderSDKVersion?: OptionalDemoConfigQueryParam["fitFinderSDKVersion"];
    legacyFitFinderSDKHost?: OptionalDemoConfigQueryParam["legacyFitFinderSDKHost"];
  };
  shop: {
    shopPrefix: RequiredDemoConfigQueryParam["shopPrefix"];
    shopCountry: RequiredDemoConfigQueryParam["shopCountry"];
    shopLanguage: RequiredDemoConfigQueryParam["shopLanguage"];
    userLanguage?: OptionalDemoConfigQueryParam["userLanguage"];
    shopSessionId: RequiredDemoConfigQueryParam["shopSessionId"];
    sessionId: RequiredDemoConfigQueryParam["sid"];
    shortSid: RequiredDemoConfigQueryParam["shortSid"];
    userId?: OptionalDemoConfigQueryParam["userId"];
  };
  product: {
    thumbnailUrl?: OptionalDemoConfigQueryParam["thumbnailUrl"];
    currentItemSubgroupId: BehaviorSubject<
      RequiredDemoConfigQueryParam["currentItemSubgroupId"]
    >;
    allItemSubgroupIds: RequiredDemoConfigQueryParam["currentItemSubgroupId"][];
    sizes: RequiredDemoConfigQueryParam["sizes"];
  };
  flags: {
    useLegacyInteraction?: OptionalDemoConfigQueryParam["useLegacyInteraction"];
    shouldInitializeFitFinder?: OptionalDemoConfigQueryParam["shouldInitializeFitFinder"];
  };
};
