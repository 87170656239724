import {
  CountryCode,
  LanguageCode,
} from "@fit-finder-sdk/client-api/dist/io-ts-gen-types";
import * as t from "io-ts";
import { StyleRules } from "../dom-manipulations/element-manipulations";
import type { BehaviorSubject } from "rxjs";
import { functionType, partialRecord } from "../io-ts/io-ts.type-wrappers";

export const Size = t.type({
  isAvailable: t.boolean,
  value: t.string,
});

export const Sizes = t.array(Size);

export type Size = t.TypeOf<typeof Size>;
export type Sizes = t.TypeOf<typeof Sizes>;

export const AnchorPlacement = t.union([
  t.literal("before"),
  t.literal("after"),
  t.literal("prepend"),
  t.literal("append"),
  t.undefined,
]);
export type AnchorPlacement = t.TypeOf<typeof AnchorPlacement>;

export const ArrayCopies = t.record(
  t.string,
  t.tuple([
    t.string,
    t.string,
    t.union([t.string, t.undefined]),
    t.union([t.string, t.undefined]),
  ]),
);
export type ArrayCopies = t.TypeOf<typeof ArrayCopies>;

export const Copies = t.type({
  default: t.string,
  recommendation: t.string,
  outOfStock: t.string,
  outOfScale: t.string,
});

export type Copies = t.TypeOf<typeof Copies>;

export const ObjectCopy = t.partial({
  formal: Copies,
  informal: Copies,
});
export type ObjectCopy = t.TypeOf<typeof ObjectCopy>;

export const ObjectCopies = partialRecord(LanguageCode, ObjectCopy);

export type ObjectCopies = t.TypeOf<typeof ObjectCopies>;

export const ButtonAttributes = t.partial({
  tabindex: t.any,
  role: t.any,
  onmouseover: t.any,
  onmouseenter: t.any,
  onmouseleave: t.any,
});

export type ButtonAttributes = t.TypeOf<typeof ButtonAttributes>;

export const ButtonText = ObjectCopies;
export type ButtonText = t.TypeOf<typeof ButtonText>;

export const ButtonRequired = t.type({
  key: t.string,
  anchor: t.string,
  anchorPlacement: AnchorPlacement,
});

export const ButtonOptionals = t.partial({
  imageSrc: t.string,
  buttonAttrs: ButtonAttributes,
  textAttrs: ButtonAttributes,
  imageAttrs: ButtonAttributes,
  appendImage: t.boolean,
  shouldShowOutOfStockText: t.boolean,
  shouldShowOutOfScaleText: t.boolean,
  disableDefaultStyles: t.boolean,
  text: ButtonText,
  style: t.partial({
    button: StyleRules,
    text: StyleRules,
    image: StyleRules,
    ariaCSS: StyleRules,
  }),
});

export const Button = t.intersection([ButtonRequired, ButtonOptionals]);

export type Button = t.TypeOf<typeof Button>;

export const MiniBehaviorSubject = t.type({
  subscribe: t.any,
  next: t.any,
  getValue: t.any,
});

export type MiniBehaviorSubject<T> = {
  next: (value: T) => void;
  subscribe(callback: (value: T) => void): () => void;
  getValue: () => T;
};

export const GlobalEvents = t.partial({
  fit_finder_open: functionType<(serial: string) => void>(),
  fit_finder_close: functionType<(serial: string, size: string) => void>(),
});

export type GlobalEvents = t.TypeOf<typeof GlobalEvents>;

export const privateSession = t.union([t.literal("0"), t.literal("1")]);
export type PrivateSession = t.TypeOf<typeof privateSession>;

export const ShopRequired = t.type(
  {
    shopPrefix: t.string,
    shopLanguage: LanguageCode,
    shopCountry: CountryCode,
    rootDomain: t.string,
    iid: t.string,
    shortSid: t.string,
    sessionId: t.string,
    shopSessionId: t.string,
    privateSession: privateSession,
  },
  "ShopRequired",
);

export const experiments = t.record(t.string, t.string);
export type Experiments = t.TypeOf<typeof experiments>;

export const platform = t.union([t.literal("mobile"), t.literal("desktop")]);
export type Platform = t.TypeOf<typeof platform>;

export const ShopOptional = t.partial(
  {
    userLanguage: t.union([LanguageCode, t.undefined]),
    userId: t.union([t.string, t.undefined]),
    blockedLocales: t.union([t.array(t.string), t.undefined]),
    consent: t.partial({
      hasShopAnalyticsConsent:
        functionType<() => Promise<{ hasAnalyticsConsent: boolean }>>(),
      hasFitFinderAnalyticsConsent: functionType<() => boolean>(),
      getFitFinderPrivacyPolicyVersion: functionType<() => string>(),
    }),
    brandLogo: t.string,
    experiments: experiments,
    platform: platform,
  },
  "ShopOptional",
);

export const ShopConfiguration = t.intersection(
  [ShopRequired, ShopOptional],
  "ShopConfiguration",
);

export const ProductRequired = t.type(
  {
    sizes: Sizes,
    allItemSubgroupIds: t.array(t.string),
    buttons: t.array(Button),
  },
  "ProductRequired",
);

export const ProductOptional = t.partial(
  {
    thumbnailUrl: t.union([t.string, t.undefined]),
    shopSizingSystem: t.union([t.string, t.undefined]),
    addToCart: functionType<(itemSubgroupId: string, size: string) => void>(),
  },
  "ProductOptional",
);

export const ProductConfiguration = t.intersection(
  [ProductRequired, ProductOptional],
  "ProductConfiguration",
);

export const Theme = t.partial(
  {
    colors: t.partial({
      brand: t.partial({
        primary: t.string,
        active: t.string,
        hover: t.string,
      }),
    }),
  },
  "Theme",
);

export type Theme = t.TypeOf<typeof Theme>;

const IntegrationConfigurationOptional = t.partial({
  theme: Theme,
  globalEvents: GlobalEvents,
});

type IntegrationConfigurationOptional = t.TypeOf<
  typeof IntegrationConfigurationOptional
>;

const IntegrationConfigurationRequired = t.type({
  shop: ShopConfiguration,
  product: ProductConfiguration,
});

type IntegrationConfigurationRequired = t.TypeOf<
  typeof IntegrationConfigurationRequired
>;

export const IntegrationConfiguration = t.intersection(
  [IntegrationConfigurationOptional, IntegrationConfigurationRequired],
  "IntegrationConfiguration",
);

export type IntegrationConfiguration = t.TypeOf<
  typeof IntegrationConfiguration
> & {
  product: {
    currentItemSubgroupId: BehaviorSubject<string>;
  };
};
