/**
 * Retrieves the keys of an object as an array of the object's key types.
 *
 * @template T - The type of the object.
 * @param obj - The object from which to retrieve the keys.
 * @returns An array of the keys of the object, typed as the keys of the object.
 */
export function getKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

/**
 * Retrieves the entries of an object as an array of key-value pairs.
 *
 * @template T - The type of the object.
 * @param obj - The object from which to retrieve the entries.
 * @returns An array of key-value pairs from the object.
 */
export function getEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

/**
 * Retrieves the values of an object as an array of the object's value types.
 *
 * @template T - The type of the object.
 * @param obj - The object from which to retrieve the values.
 * @returns An array of the values of the object, typed as the values of the object.
 */
export function getValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}
